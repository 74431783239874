import * as React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export default function ServiceAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div className="space-y-8 max-w-3xl desktop:max-w-5xl w-full mx-auto t">
      <Accordion 
        disableGutters 
        elevation={0} 
        square 
        expanded={expanded === 'panel1'} 
        onChange={handleChange('panel1')}
        className="accordion"
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon className="accordionIcon" />}
          aria-controls="panel1d-content"
          id="panel1d-header"
          className="accordionSummary"
        >
          <Typography className="typographyHeader">
            Full-Stack Web Development
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <Typography className="typographyText">
          I bring your ideas to life with efficient, dynamic, and reliable web development. 
          Using the latest front-end frameworks and back-end technologies, I create responsive, cross-browser websites 
          and custom web applications tailored to your needs. My approach ensures flawless functionality, testing, and adherence to the latest
          industry standards for optimal performance.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion 
        disableGutters 
        elevation={0} 
        square 
        expanded={expanded === 'panel2'} 
        onChange={handleChange('panel2')}
        className="accordion"
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon className="accordionIcon" />}
          aria-controls="panel2d-content"
          id="panel2d-header"
          className="accordionSummary"
        >
          <Typography className="typographyHeader">
            Ongoing Website Maintenance
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <Typography className="typographyText">
          Keep your website running smoothly with regular updates, content management, and 
          security patches. I monitor performance, back up data, and resolve any technical 
          issues promptly, ensuring your website remains secure, fast, and reliable so you 
          can focus on running your business.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion 
        disableGutters 
        elevation={0} 
        square 
        expanded={expanded === 'panel3'} 
        onChange={handleChange('panel3')}
        className="accordion"
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon className="accordionIcon" />}
          aria-controls="panel3d-content"
          id="panel3d-header"
          className="accordionSummary"
        >
          <Typography className="typographyHeader">
            Custom UI Design
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <Typography className="typographyText">
          Create visually appealing and user-oriented website designs that leave a lasting 
          impression. I ensure consistent branding, design custom graphics, and develop 
          responsive layouts optimised for different devices. By working closely with clients, 
          I craft unique designs that reflect their vision and provide an exceptional user experience.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion 
        disableGutters 
        elevation={0} 
        square 
        expanded={expanded === 'panel4'} 
        onChange={handleChange('panel4')}
        className="accordion"
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon className="accordionIcon" />}
          aria-controls="panel3d-content"
          id="panel3d-header"
          className="accordionSummary"
        >
          <Typography className="typographyHeader">
            Targeted SEO Services
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <Typography className="typographyText">
          Improve your online visibility with tailored SEO strategies. I conduct keyword 
          research, optimise website content, and implement on-page SEO techniques to boost 
          your search engine rankings. I also monitor analytics and provide insights to 
          help drive organic traffic and improve your online presence.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion 
        disableGutters 
        elevation={0} 
        square 
        expanded={expanded === 'panel5'} 
        onChange={handleChange('panel5')}
        className="accordion"
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon className="accordionIcon" />}
          aria-controls="panel3d-content"
          id="panel3d-header"
          className="accordionSummary"
        >
          <Typography className="typographyHeader">
            Mobile App Development
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <Typography className="typographyText">
          I specialise in developing efficient, user-friendly mobile applications using Java 
          and the MVVM architecture. This approach ensures a clear separation between the user 
          interface and business logic, making the apps easier to maintain and test. My expertise 
          includes integrating APIs and optimising performance for Android platforms. With a 
          focus on scalable, well-structured code, I aim to deliver applications that are both 
          functional and responsive.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}
